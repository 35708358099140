import React from 'react'; // eslint-disable-line no-unused-vars
import { Component } from 'react';
import ReactDOM from 'react-dom'; // eslint-disable-line no-unused-vars

import { Provider } from 'react-redux';
// import { createStore } from 'redux';
// import { reducer } from './reducer';

import logo from './logo.svg';
import './App.css';

import { DefinitionsContainer } from './definitions';
import { LookupContainer } from './lookup';
import { store } from './store';

class App extends Component {
    render() {
        return (
            <Provider store={store}>
              <div className="App">
                <LookupContainer />

                <DefinitionsContainer />
              </div>
            </Provider>
        );
    }
}

export default App;

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
