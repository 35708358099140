import isFunction from 'lodash/isFunction';

import { Word } from './word';

let lookupServiceInstance: LookupService | null = null;
class LookupService {
    url = 'https://philologue.net/lookup';

    constructor() {
        if (lookupServiceInstance !== null) {
            console.log('already have a LookupService instance; recycling...');
            return lookupServiceInstance;
        }
        console.log(`creating new LookupService instance`);
        // this.url = 'http://localhost:7777';

        lookupServiceInstance = this;
    }

    ltoe(w0: string, cb: (w: Word) => void) {
        const w = new Word(w0, 'latin-english2');
        this.lookup(w, cb);
    }

    etol(w0: string, cb: (w: Word) => void) {
        const w = new Word(w0, 'english-latin2');
        this.lookup(w, cb);
    }

    lookup(word: Word, cb: (w: Word) => void) {
        const lurl = `${this.url}/${word.direction}/${word.word}`;
        console.log(`will send request to: ${lurl}`);
        fetch(lurl)
            .then((response) => response.json())
            .then((responseJson) => {
                word.definition = responseJson.payload;
                word.resolve();
                if (isFunction(cb)) {
                    cb(word);
                }
            })
            .catch((error) => {
                console.log(`error: ${error}`);
            });
    }
}

export { LookupService };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
