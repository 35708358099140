import { Util } from './util';
import Defn from './defn';
class Word {
    id: string;
    direction: string | null;
    word: string;
    definition: Defn[] | null;
    selected: boolean;
    resolved: boolean;
    _cf: string;

    constructor(word: string, direction: string) {
        if (!word) {
            // https://gogs.philologue.net/mw/words/issues/15
            console.log('word can not be falsy');
            throw {};
        }
        this.id = `word${Util.uniqueId()}`;
        this.word = word;
        this.direction = direction;
        this.definition = null;
        this.selected = false;
        this.resolved = false;
        this._cf = `${this.word}:${this.direction}`;
    }

    resolve() {
        this.resolved = true;
    }

    cf() {
        return this._cf;
    }

    equals(w: Word) {
        // if (this.cf() === w.cf()) {
        if (this.id === w.id) {
            return true;
        }
        return false;
    }

    toString() {
        return JSON.stringify(this);
    }
}

export { Word };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
