import React, { Component, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store } from './store';

import { LookupService } from './lookup.service';
import { Word } from './word';

const ls = new LookupService();

function Lookup({ lookupText }: { lookupText: string }) {
    const _handleKeyPress = function(event: any) {
        if (event.key === 'Enter') {
            _updateLookupText(event);
            _ltoe();
        }
    };

    //React.FormEvent<HTMLInputElement>
    const _updateLookupText = function(
        // event: React.FormEvent<HTMLInputElement>,
        event: any,
    ) {
        store.dispatch({
            type: 'SET_LOOKUP_TEXT',
            lookupText: event.target.value,
        });
    };

    const _setForm = () => {
        const state = store.getState();
        const newIndex = state.words.length - 1;
        store.dispatch({ type: 'UNSET_LOOKUP_TEXT' });
        store.dispatch({ type: 'SET_SELECTED_INDEX', selectedIndex: newIndex });
    };

    const _ltoe = function() {
        ls.ltoe(store.getState().lookupText, (word: Word) => {
            console.log(`tacos: ${word.word}`);
            store.dispatch({ type: 'ADD_WORD', word: word });
            _setForm();
        });
    };

    const _etol = function() {
        ls.etol(store.getState().lookupText, (word: Word) => {
            console.log(`burritos: ${word.word}`);
            store.dispatch({ type: 'ADD_WORD', word: word });
            _setForm();
        });
    };

    return (
        <div>
            <div className="form-group">
                <input
                    type="text"
                    id="word-input"
                    required
                    value={lookupText}
                    onChange={_updateLookupText}
                    onKeyPress={_handleKeyPress}
                />
            </div>

            <div className="form-group">
                <button id="latin-to-english" onClick={_ltoe}>
                    Latin to English
                </button>
                <button id="english-to-latin" onClick={_etol}>
                    English to Latin
                </button>
            </div>
        </div>
    );
}

const getLookupText = (lookupText: string) => {
    return lookupText;
};

const mapStateToProps = (state: any) => {
    return {
        lookupText: getLookupText(state.lookupText),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const LookupContainer = connect(mapStateToProps, mapDispatchToProps)(Lookup);

export { LookupContainer, Lookup };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
