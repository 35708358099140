import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import map from 'lodash/map';
import reject from 'lodash/reject';

import Action from './action';
import State from './state';
import { Word } from './word';

const defaultState: State = {
    lookupText: '',
    words: [],
    selectedIndex: null,
};

interface Retval {
    lookupText: string | null;
}

function reducer(state = defaultState, action: Action) {
    if (!action) {
        return state;
    }
    console.log(`${action.type}`);
    const retval: State = Object.assign({}, state);
    let sidx = -1;
    switch (action.type) {
        case 'SET_LOOKUP_TEXT':
            console.log(`now setting it to ${action.lookupText}`);
            if (action.lookupText) {
                // keep the type checker happy for now
                retval.lookupText = action.lookupText;
            }
            break;
        case 'UNSET_LOOKUP_TEXT':
            retval.lookupText = '';
            break;
        case 'ADD_WORD':
            retval.words = [...state.words, action.word];
            retval.words = map(retval.words, (ele: Word) => {
                if (ele.id === action.word.id) {
                    ele.selected = true;
                } else {
                    ele.selected = false;
                }
                return ele;
            });
            break;
        case 'SELECT_TAB':
            if (action.selectedIndex) {
                sidx = action.selectedIndex.tabIndex;
            }
            retval.words = map(retval.words, (ele: Word, idx) => {
                if (idx === sidx) {
                    ele.selected = true;
                } else {
                    ele.selected = false;
                }
                return ele;
            });
            break;
        case 'SET_SELECTED_INDEX':
            retval.selectedIndex = { tabIndex: action.selectedIndex };
            break;
        case 'REMOVE_WORD':
            const idx = findIndex(retval.words, (ele: Word) => {
                return ele.id === action.word.id;
            });

            retval.words = reject(retval.words, (ele) => {
                return ele.id === action.word.id;
            });
            break;
        default:
            break;
    }

    return retval;
}

export { defaultState, reducer };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
