import findIndex from 'lodash/findIndex';
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

import { store } from './store';
import Action from './action';
import { Word } from './word';
import { Definition } from './definition';

enum RelativePosition {
    Before,
    Same,
    After,
};
function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}

function Definitions({
    words,
    selectedIndex,
}: {
    words: Array<Word>,
    selectedIndex: { tabIndex: number },
}) {



    const _getRelativePositions = (
        closedIndex: number,
        selectedIndex: number,
    ): RelativePosition => {
        if (closedIndex < selectedIndex) {
            return RelativePosition.Before;
        } else if (closedIndex === selectedIndex) {
            return RelativePosition.Same;
        } else {
            // (selectedIndex < closedIndex) {
            return RelativePosition.After;
        }
    };

    const remove = function(w: Word) {
        const si = selectedIndex.tabIndex;
        const len = words.length;
        const wordIndex = findIndex(words, (ele) => {
            return ele === w;
        });

        store.dispatch({ type: 'REMOVE_WORD', word: w });
        
        const rp = _getRelativePositions(wordIndex, si);
        let newSelectedIndex: number = si;
        switch (rp) {
        case RelativePosition.Same:
            if (si === len - 1) {
                newSelectedIndex = si - 1;
            }
            break;
        case RelativePosition.Before:
            newSelectedIndex = si - 1;
            break;
        case RelativePosition.After:
            newSelectedIndex = si;
            break;
        default:
            assertUnreachable(rp);
            break;
        }

        setSelectedIndex(newSelectedIndex);

        return;
    };

    const tt = words.map((w: Word) => {
        const rf = (e: any) => {
            e.stopPropagation();
            remove(w);
        };
        return (
            <Tab>
                {w.word}{' '}
                <span>
                    &nbsp;
                    <span className="closer" onClick={rf}>
                        X
                    </span>
                </span>
            </Tab>
        );
    });

    const definitions = words.map((w: Word) => {
        return (
            <TabPanel>
                <Definition word={w} />
            </TabPanel>
        );
    });

    const setSelectedIndex = (tabIndex: number) => {
        store.dispatch({
            type: 'SELECT_TAB',
            selectedIndex: { tabIndex },
        });
        store.dispatch({ type: 'SET_SELECTED_INDEX', selectedIndex: tabIndex });
    };

    return (
        <Tabs
            selectedIndex={selectedIndex ? selectedIndex.tabIndex : 0}
            onSelect={(tabIndex) => setSelectedIndex(tabIndex)}
        >
            <TabList id="definition-tab-list">{tt}</TabList>
            {definitions}
        </Tabs>
    );
}

const getAllDefinitions = (words: Array<Word>) => {
    return words;
};

const mapStateToProps = (state: any) => {
    return {
        words: getAllDefinitions(state.words),
        selectedIndex: state.selectedIndex,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const DefinitionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Definitions);

export { DefinitionsContainer, Definitions };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
