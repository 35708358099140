import map from 'lodash/map';
// import each from 'lodash/each';

import React from 'react'; // eslint-disable-line no-unused-vars

import { store } from './store';
import { Word } from './word';
import Defn from './defn';

function Definition(props: { word: Word }) {
    let lemmaCount = 0;
    const ww = map(props.word.definition, function(l: Defn) {
        let ret: JSX.Element;
        switch (l.type) {
            case 'parse':
                switch (l.parse.pos) {
                    case 'N':
                    case 'ADJ':
                    case 'PRON':
                        ret = (
                            <div className="parse n adj pron">
                                {l.parse.stem}&bull;{l.parse.ending}:
                                {l.parse.declension} {l.parse.case}{' '}
                                {l.parse.number}
                                &nbsp;{l.parse.gender}
                                {l.parse.pos === 'ADJ' ? (
                                    <span>&nbsp;{l.parse.strength}</span>
                                ) : (
                                    <span></span>
                                )}
                            </div>
                        );
                        break;
                    case 'V':
                        ret = (
                            <div className="parse v">
                                {l.parse.stem}&bull;{l.parse.ending}: &nbsp;
                                {l.parse.conjugation} {l.parse.time}{' '}
                                {l.parse.voice}
                                &nbsp;{l.parse.mood} {l.parse.person}{' '}
                                {l.parse.number}
                            </div>
                        );
                        break;
                    case 'ADV':
                        ret = (
                            <div className="parse adv">
                                {l.parse.stem} {l.parse.something}
                            </div>
                        );
                        break;
                    case 'VPAR':
                        ret = (
                            <div className="parse vpar">
                                {l.parse.stem}&bull;{l.parse.ending}: &nbsp;
                                {l.parse.conjugation} {l.parse.time}{' '}
                                {l.parse.voice}
                                &nbsp;{l.parse.case} {l.parse.number}
                            </div>
                        );
                        break;
                    case 'SUPINE':
                        ret = (
                            <div className="parse supine">
                                {l.parse.stem}&bull;{l.parse.ending}: &nbsp;
                                {l.parse.conjugation}
                                &nbsp;{l.parse.case} {l.parse.number}{' '}
                                {l.parse.gender}
                            </div>
                        );
                        break;
                    case 'PREP':
                        ret = (
                            <div className="parse prep">
                                {l.parse.stem} {l.parse.governs}
                            </div>
                        );
                        break;
                    case 'CONJ':
                    case 'INTERJ':
                        ret = <div className="parse conj interj">{l.parse.stem}</div>;
                        break;
                    case 'NUM':
                        ret = <div className="parse num">{l.parse.stem}</div>;
                        break;
                    default:
                        console.log('unhandled');
                        ret = <div className="parse unhandled">unhandled</div>;
                        break;
                }
                break;
            case 'lemma':
                const lid = `${props.word.word}-${lemmaCount}`;
                ret = <div id={ lid } className="lemma">{l.lemma}</div>;
                break;
            case 'definition':
                ret = <div className="definition">{l.definition}</div>;
                break;
        }

        return ret;
    });

    return <div>{ww}</div>;
}

export { Definition };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
