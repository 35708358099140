import throttle from 'lodash/throttle';

import { createStore } from 'redux';

import State from './state';

import { defaultState, reducer } from './reducer';

console.log('Setting up store store');

var loadState = (): State => {
    console.log('Attempting to load saved state...');
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            console.log('\t...no saved state');
            return defaultState;
        }
        console.log('\t...found saved state.');
        return JSON.parse(serializedState);
    } catch (err) {
        console.log('\t...found saved state but unable to parse it');
        console.log('\t...returning empty state as a fallback');
        return defaultState;
    }
};

var saveState = (state: State) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        console.log('error serializing state');
    }
};

const state = loadState();

const store = createStore(reducer, state);

// This should be made to be more sophisticated.  See
// https://gogs.philologue.net/mw/words/issues/11 for discussion.
store.subscribe(
    throttle(() => {
        saveState(store.getState());
    }, 100),
);

console.log('created store');

export { store };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
