import v4 from 'uuid/v4';

const Util = (function() {
    const mkUnique = () => {
        const retval = v4();
        return retval;
    };

    return {
        uniquePrefix: (pfx: string) => {
            return `${pfx}${mkUnique()}`;
        },

        uniqueId: () => {
            return mkUnique();
        },
    };
})();

export { Util };

// Local Variables:
// mode: web
// eval: (web-mode-set-content-type "jsx")
// eval: (flycheck-mode)
// End:
